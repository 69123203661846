/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../components/seo"
import { Link } from 'gatsby'

import Page from "../layouts/page";

import CraftMat from "../images/craftmat.svg"
import IconTick from "../images/icons/check.svg"
import IconArrowRight from "../images/icons/arrow-right.svg"

const productListData = [
  {
    title: 'RIPE ASN',
    subtitle: '£45 one-off',
    points: [
      'RIPE AS number',
      'Application support',
      'Fast turn-around'
    ],
    cta: 'Order',
    url: '/lir-services'
  }, {
    title: 'RIPE IP Space',
    subtitle: 'Starting from £30/yr',
    points: [
      'RIPE RIR IP space',
      'PI and PA available',
      'IPv6 and IPv4 available'
    ],
    cta: 'Order',
    url: '/lir-services'
  }, {
    title: 'Colocation',
    subtitle: 'Enquiries only',
    points: [
      'Central London datacentre',
      'Custom arrangements',
      'Remote hands'
    ],
    cta: 'Enquire',
    url: '/colocation'
  }, {
    title: 'Event ticketing',
    subtitle: 'Enquiries only',
    points: [
      'Digital ticketing and payments',
      'Greater insights on customers',
      'All-in-one solution'
    ],
    cta: 'Enquire',
    url: '/contact-us' // TODO: this should have it's own page
  }
]

const styles = css`
color: #fff;
//background: #232323;

.topCover {
  background-color: #232323;
  background-image: url(${CraftMat});
  min-height: calc(100vh - 120px - 28.5px);
  //height: calc(80vh - 120px - 28.5px);

  h1, h2 {
    color: #fff;
  }

  h1 {
    margin-bottom: 0;
  }
  h2 {
    margin-top: 15px;
  }
  a {
    color: #fff;
  }
}
`

const topBanner = css`
text-align: center;
padding-top: 5vh;

h1 {
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
}
`

const productList = css`
display: flex;
justify-content: space-evenly;
margin-top: 5vh;
padding-bottom: 5vh;

.product {
  background: #232323;
  width: 240px;
  outline: 1px solid #282828;
  border: 1px solid #ddd;
  border-radius: 0;

  .head {
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 20px 0 20px 0;
    
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .subtitle {
      font-weight: lighter;
    }
  }
  .body {
    font-size: 14px;
    font-weight: lighter;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 8px 10px 8px 10px;
        border-bottom: 1px dashed #ddd;

        span.tick {
          display: inline-block;

          background-image: url(${IconTick});
          background-size: 16px 16px;
          filter: invert(1);

          height: 16px;
          width: 16px;

          margin-right: 2px;

          margin-top: -2px;
          vertical-align: middle;
        }
      }
    }

    .cta {
      padding: 16px;
      text-align: center;

      a:hover {
        color: inherit;
      }

      .button {
        display: inline-block;
        padding: 8px 16px 8px 16px;

        background: #2e2e2e;
        border: 1px solid #2e2e2e;

        .arrow-right {
          display: inline-block;

          background-image: url(${IconArrowRight});
          background-size: 16px 16px;
          filter: invert(1);

          height: 16px;
          width: 16px;

          vertical-align: middle;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  display: block;
  
  .product {
    width: 80%;
    margin: 0 auto;

    margin-bottom: 24px;

    text-align: center;
  }
}
`



// markup
const IndexPage = () => {
  return <Page>
    <div css={styles}>
      <div className="topCover">       
        <div className="contain">
          <div css={topBanner}>
            <h1>Your digital solutions partner</h1>
            {/* <h2>Something else</h2> */}
          </div>

          <div css={productList}>
            {productListData.map((product) => 
            <div className="product" key={product.title}>
              <div className="head">
                <div className="title">{product.title}</div>
                <div className="subtitle">{product.subtitle}</div>
              </div>

              <div className="body">
                <ul>
                {product.points.map((point) => 
                  <li key={point}>
                    <span className="tick"></span> {point}
                  </li>
                )}
                </ul>

                <div className="cta">
                  <Link to={product.url}>
                    <div className="button">{product.cta} <span className="arrow-right"></span></div>
                  </Link>
                </div>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    description="Your digital solutions partner for colocation and RIPE LIR services"
    path="/"
  />
)

export default IndexPage